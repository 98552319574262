<template>
  <div>
    <div class="context">
      <div class="button_time">
        <div class="button">
          <div class="publish" @click="issueSelect">发布已选</div>
          <div class="remove" @click="clickRemoveAll">
            <i class="el-icon-close"></i>
            移除已选
          </div>
        </div>
        <div class="time">
          <div class="icon">
            <img src="@/assets/image/operator/jr-icon-volume-copy.png" alt="" />
          </div>
          <div class="text">最新上架时间：</div>
          <div class="times">{{ newTime | timeFiltration }}</div>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loading"
          @sort-change="changeSort"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中..."
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column type="selection" width="55" align="center"> </el-table-column>
          <el-table-column label="编号" align="center" type="index" width="80"> </el-table-column>

          <el-table-column label="logo" align="center">
            <template v-slot="{ row }">
              <div class="imgBox">
                <img class="img" v-if="row.companyLogo" :src="row.companyLogo" alt="" />
                <img v-else class="img" :src="require('@/assets/image/Group 3237.png')" alt="" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" align="center">
            <template v-slot="{ row }">{{ row.companyFullName }}</template>
          </el-table-column>
          <el-table-column label="所属行业" align="center">
            <template v-slot="{ row }">{{ row.industry }}</template>
          </el-table-column>
          <el-table-column label="所在城市" align="center">
            <template v-slot="{ row }">{{ row.province }}-{{ row.city }}</template>
          </el-table-column>

          <el-table-column align="center" label="标签" width="200px">
            <template v-slot="{ row }">
              <span v-for="(o, index) in row.companyLabel" :key="index">
                <span v-if="index < 4 && o != ''" class="span_tag">#{{ o }}</span>
              </span>
              <el-popover placement="bottom" width="230" trigger="hover">
                <div
                  class="hidden_label_box"
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    flex-wrap: wrap;
                  "
                >
                  <span
                    class="span_tag"
                    v-for="(o, index) in row.companyLabel && row.companyLabel.length
                      ? row.companyLabel.filter((el, index) => index > 3)
                      : []"
                    :key="index"
                    style="
                      font-family: 'PingFang SC';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      color: #4e93fb;
                      margin-right: 10px;
                    "
                    >#{{ o }}</span
                  >
                </div>
                <span
                  style="color: #4e93fb"
                  slot="reference"
                  v-if="row.companyLabel && row.companyLabel.length > 4"
                  >...{{ row.companyLabel.length - 4 }}</span
                >
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="关联度" prop="correlation" align="center" sortable>
            <template v-slot="{ row }">
              <div class="correlationBox">
                <div class="correlation" v-if="row.correlation">
                  {{ row.correlation + '%' }}
                </div>
                <div class="correlation" v-else>
                  {{ '0%' }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="企业简介" align="center" width="350">
            <template v-slot="{ row }">
              <div
                class="enterpriseIntro"
                @mouseenter="enterpriseIntroShow = row.id"
                @mouseleave="enterpriseIntroShow = -1"
              >
                {{ row.enterpriseIntro }}
              </div>
              <div
                class="enterpriseIntroShow"
                v-show="enterpriseIntroShow == row.id"
                @mouseenter="enterpriseIntroShow = row.id"
                @mouseleave="enterpriseIntroShow = -1"
              >
                {{ row.enterpriseIntro }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="{ row }">
              <div class="tableRemove">
                <div @click="clickRemove(row)" class="tableRemoves">
                  <i class="el-icon-close"></i>
                  <div class="txt">移除</div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="Pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="query.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="query.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  listPageMatched,
  businessNotReleaseMatched,
  businessDeleteByIdMatched
} from '@/api/operatorCenter'

export default {
  props: {
    id: {
      type: String
    }
  },
  components: {},
  filters: {
    timeFiltration(time) {
      if (time) {
        return time.split(' ')[0]
      }
    }
  },
  data() {
    //这里存放数据
    return {
      matchingType: [
        {
          value: '算法匹配',
          id: 0
        },
        {
          value: '人工匹配',
          id: 1
        }
      ],
      //  匹配类型：选中的值
      radio: '',
      // 表格数据
      tableData: [],
      loading: false,
      // 表格企业简介
      enterpriseIntroShow: -1,
      // 表格选中的数据项
      multipleSelection: [],
      // 表格总数据条数
      total: 0,
      // 发送的数据
      query: {
        companyFullName: '', // 企业全称
        businessId: null, // 商机编号
        correlation: 0,
        id: 1,
        pageNum: 1,
        pageSize: 10,
        orders: 1 // 排序 默认传1 2->关联度升序;3->关联度降序
      }, // 表格数据查询传参
      //   最新上架时间
      newTime: ''
    }
  },

  watch: {},

  methods: {
    // table表格按钮的选中数据
    handleSelectionChange(val) {
      if (val) {
        this.multipleSelection = val
      }

      // console.log(this.multipleSelection)
    },
    // table的移除
    async clickRemove(row) {
      const { businessId, companyId, id } = row
      const list = [{ businessId, companyId, id }]
      const { data: res } = await businessDeleteByIdMatched(list)
      if (res.resultCode == 200) {
        this.search()
        this.$emit('tab', 1)
        return this.$message.success('操作成功')
      }
      // console.log(res)
    },
    // 批量移除
    async clickRemoveAll() {
      if (this.multipleSelection.length == 0) {
        this.$message('请勾选要移除的数据')
      } else {
        let list = []
        this.multipleSelection.forEach((item) => {
          const { businessId, companyId, id } = item
          const obj = { businessId, companyId, id }
          list.push(obj)
        })
        const { data: res } = await businessDeleteByIdMatched(list)
        if (res.resultCode == 200) {
          this.search()
          this.$emit('tab', 1)
          return this.$message.success('操作成功')
        }
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },
    async search() {
      this.loading = true
      this.query.businessId = this.id
      const { data: res } = await listPageMatched(this.query)
      if (res.resultCode === 200) {
        this.total = res.data.total
        this.tableData = res.data.list
      }
      this.loading = false
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'correlation') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.query.orderByParam = 'm.correlation asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.query.orderByParam = 'm.correlation desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orderByParam = 'm.correlation desc'
          this.search()
        }
      }
    },
    // 发布已选
    async issueSelect() {
      if (this.multipleSelection.length == 0) {
        this.$message('请勾选要发布的数据')
      } else {
        const ids = this.multipleSelection.map((item) => item.id)
        const { data: res } = await businessNotReleaseMatched(ids)
        if (res.resultCode === 200) {
          this.$message.success('发布成功')
          this.search()
        }
      }
    }
  },

  created() {
    // console.log(this.id)
    this.search()
    this.newTime = sessionStorage.getItem('time') || ''
  },

  mounted() {}
}
</script>
<style scoped lang="less">
.context {
  background-color: #fff;
  margin-top: 16px;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .span_tag {
    font-size: 14px;
    line-height: 20px;
    color: #4e93fb;
    margin-right: 10px;
    cursor: pointer;
  }

  .button_time {
    display: flex;
    justify-content: space-between;

    .button {
      display: flex;
      margin-bottom: 16px;
      .publish {
        width: 85px;
        height: 40px;
        border-radius: 4px;
        background-color: #4e93fb;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        margin-right: 15px;
        cursor: pointer;
      }
      .remove {
        box-sizing: border-box;
        width: 85px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        border: 1px solid #ff7d18;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff7d18;
        font-size: 14px;
        cursor: pointer;
        i {
          margin-right: 4px;
          line-height: 40px;
        }
      }
    }
    .time {
      display: flex;
      padding-right: 60px;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .table {
    .imgBox {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      .img {
        width: 34px;
        height: 34px;
      }
    }

    .correlationBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .correlation {
        background-color: #fd6161;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 24px;
        color: #fff;
        font-size: 12px;
      }
    }

    .enterpriseIntro {
      // width: 100%;
      // height: 100%;
      width: 376px;
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
      // 控制行数
      -webkit-line-clamp: 2; //超出两行隐藏
      -webkit-box-orient: vertical; // 从上到下垂直排列子元素
      color: #333333;
      position: relative;
    }
    .enterpriseIntroShow {
      position: absolute;
      // right: 0%;
      // bottom: -23%;
      border-radius: 8px;
      // transform: translateX(50%);
      width: 376px;
      padding: 10px 7px 8px 13px;
      background-color: #fff;
      z-index: 999;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    /deep/.el-table__body-wrapper {
      overflow: visible;
    }
    /deep/.el-table {
      overflow: visible;
    }

    .tableRemove {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // color: #ff7d18;
      .tableRemoves {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff7d18;
        .txt {
          font-size: 14px;
          cursor: pointer;
        }
        /deep/.el-icon-close {
          margin-top: 3px;
          cursor: pointer;
        }
      }
    }
  }
  .Pagination {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }
}
</style>
