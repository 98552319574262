<template>
  <div>
    <div class="head">
      <div v-for="item in headTab" :key="item.id" class="item" @click="clickTab(item)">
        <div class="text" :class="activeClass === item.id ? 'active_class' : ''">
          <div class="icon">
            <img v-if="activeClass === 1" :src="item.url" alt="" />
            <img v-else :src="item.url1" alt="" />
          </div>
          <div class="txt">{{ item.value }}</div>
          <div class="figure">({{ item.Num }})</div>
        </div>
        <div class="bottom" v-if="activeClass === item.id">
          <div class="bottom_a"></div>
        </div>
      </div>
    </div>
    <div class="context">
      <matchingCom @tab="tab" v-if="activeClass === 1" :id="id"></matchingCom>
      <notarizeSelect @tab="tab" v-else :id="id"></notarizeSelect>
    </div>
  </div>
</template>
<script>
import matchingCom from './components/matchingCom'
import notarizeSelect from './components/notarizeSelect'
import url1 from '@/assets/image/operator/jr-icon-six.png'
import url2 from '@/assets/image/operator/jr-icon-six-copy.png'
import url3 from '@/assets/image/operator/jr-icon-circle-copy.png'
import url4 from '@/assets/image/operator/jr-icon-circle.png'

// import { listPageAutomation } from '@/api/operatorCenter'
export default {
  name: 'nicheMatching',
  components: { matchingCom, notarizeSelect },

  data() {
    //这里存放数据
    return {
      id: null,
      loading: false,
      matchCompanyNum: 0,
      companyNum: 0,
      headTab: [
        {
          value: '自动匹配',
          id: 1,
          url: url1,
          url1: url2,
          Num: 0
        },
        {
          value: '确认已选',
          id: 2,
          url: url3,
          url1: url4,
          Num: 0
        }
      ],
      activeClass: 1,
      query: {
        informationType: null,
        pageNum: 1,
        pageSize: 100000000,
        orders: 1,
        companyFullName: '',
        correlation: null,
        businessId: null
      }
    }
  },
  created() {
    const id = this.$route.query.businessId
    if (id) {
      this.id = id
      this.query.businessId = id
    }
    this.search()
  },
  mounted() {},
  //方法集合
  methods: {
    clickTab(item) {
      this.activeClass = item.id
    },
    tab(val) {
      if (val) {
        this.search()
      }
    },
    async search() {
      // const { data: res } = await listPageAutomation(this.query)
      // if (res.resultCode == 200) {
      //   res.data.list.forEach((item) => {
      //     if (item.id == this.activeId) {
      //       this.headTab[0].Num = item.matchCompanyNum
      //       this.headTab[1].Num = item.companyNum
      //     }
      //   })
      // }
    }
  }
}
</script>
<style scoped lang="less">
.head {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;

  .item {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    .text {
      display: flex;
      color: #bfbfbf;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 5px;

      .icon {
        width: 15px;
        height: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        margin: 0 5px;
      }
    }
    .bottom {
      display: flex;
      justify-content: center;

      .bottom_a {
        width: 88px;
        height: 6px;
        border-radius: 3px;
        background-color: #4e93fb;
      }
    }

    .active_class {
      color: #333;
      font-size: 14px;
    }
  }
}
</style>
