<template>
  <div>
    <div
      class="all_context"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中..."
    >
      <div class="head">
        <div class="left">
          <img class="img" :src="headData.businessImage" alt="" />
          <div class="info_box">
            <div class="row">
              <div
                class="border_box"
                :class="headData.businessOpportunityCategory === 2 ? 'real_box' : ''"
              >
                {{ headData.businessOpportunityCategory === 2 ? '真实需求' : '潜在需求' }}
              </div>
              <div class="name">
                {{ headData.businessName }}
              </div>
            </div>
            <div
              class="row bottom"
              @mouseenter="showTagBox = true"
              @mouseleave="showTagBox = false"
            >
              <div class="tag color1" v-for="(item, index) in businessLabelList" :key="index">
                {{ item }}
              </div>
              <div class="color1">{{ '...' + businessLabelNum }}</div>
              <ul
                class="pos_box"
                v-show="showTagBox"
                @mouseenter="showTagBox = true"
                @mouseleave="showTagBox = false"
              >
                <li v-for="(item, index) in headData.businessLabelList" :key="index">
                  <div class="tag">
                    {{ '#' + item }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="figure" @click="clickJoin">加入已选</div>
          <div class="person" @click="clickSet">设置关联度</div>
        </div>
      </div>
      <div class="context">
        <div class="state">
          <div class="left">
            <div class="label">关联度：</div>
            <el-radio-group v-model="query.correlation" @change="search">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="item.id" v-for="item in correlationList" :key="item.id">
                {{ item.value }}
              </el-radio>
            </el-radio-group>
          </div>

          <!-- <div class="right">
            <div class="icon">
              <img src="@/assets/image/operator/jr-icon-volume-copy.png" alt="" />
            </div>
            <div class="tex">最新刷新时间：</div>
            <div class="time">{{ headData.updateTime }}</div>
          </div> -->
        </div>
        <div class="seach_box">
          <el-input
            placeholder="请输入企业全称"
            v-model="query.companyFullName"
            class="input-with-select"
            clearable
            @clear="search"
          >
            <el-button class="seach" slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @sort-change="changeSort"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="编号" align="center" width="140px" type="index">
            </el-table-column>

            <el-table-column label="logo" align="center">
              <template v-slot="{ row }">
                <div class="imgbox">
                  <img class="img" v-if="row.companyLogo" :src="row.companyLogo" alt="" />
                  <img v-else class="img" :src="require('@/assets/image/Group 3237.png')" alt="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="企业名称" align="center">
              <template slot-scope="scope">{{ scope.row.companyFullName }}</template>
            </el-table-column>
            <el-table-column label="所属行业" align="center">
              <template slot-scope="scope">{{ scope.row.industry }}</template>
            </el-table-column>
            <el-table-column label="所在城市" align="center">
              <template slot-scope="scope">{{ scope.row.province }}-{{ scope.row.city }}</template>
            </el-table-column>
            <el-table-column align="center" label="标签" width="200px">
              <template v-slot="{ row }">
                <span v-for="(o, index) in row.companyLabel" :key="index">
                  <span v-if="index < 4 && o != ''" class="span_tag">#{{ o }}</span>
                </span>
                <el-popover placement="bottom" width="230" trigger="hover">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                      class="span_tag"
                      v-for="(o, index) in row.companyLabel && row.companyLabel.length
                        ? row.companyLabel.filter((el, index) => index > 3)
                        : []"
                      :key="index"
                      style="
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #4e93fb;
                        margin-right: 10px;
                      "
                      >#{{ o }}</span
                    >
                  </div>
                  <span
                    style="color: #4e93fb"
                    slot="reference"
                    v-if="row.companyLabel && row.companyLabel.length > 4"
                    >...{{ row.companyLabel.length - 4 }}</span
                  >
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="关联度" prop="correlation" align="center" sortable="custom">
              <template v-slot="{ row }">
                <div class="correlationBox">
                  <div class="correlation" v-if="row.correlation">
                    {{ row.correlation + '%' }}
                  </div>
                  <div class="correlation" v-else>
                    {{ '0%' }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="企业简介" align="center" width="450">
              <template slot-scope="scope">
                <div
                  class="enterpriseIntro"
                  @mouseenter="enterpriseIntroShow = scope.row.id"
                  @mouseleave="enterpriseIntroShow = -1"
                >
                  {{ scope.row.enterpriseIntro }}
                </div>
                <div
                  class="enterpriseIntroShow"
                  v-show="enterpriseIntroShow == scope.row.id"
                  @mouseenter="enterpriseIntroShow = scope.row.id"
                  @mouseleave="enterpriseIntroShow = -1"
                >
                  {{ scope.row.enterpriseIntro }}
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="query.size"
              :page-sizes="[10, 30, 50]"
              :page-size="query.pageNum"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="add_select">
      <!-- 是否加入已选 -->
      <el-dialog :visible.sync="poolShow" width="347px">
        <div class="remove_pool" v-show="poolShow">
          <i class="el-icon-close" @click="clickClose"></i>
          <div class="text">确认是否加入已选</div>
          <div>
            <el-button type="primary" @click="clickPool">确认</el-button>
            <el-button @click="cancelPool">取消</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 设置关联度 -->
    <div class="correlation">
      <el-dialog :visible.sync="correlationShow" width="444px" @close="correlationClose">
        <div class="correlationBox">
          <div class="head">
            <div class="title">{{ correlationText }}</div>
          </div>
          <div class="context">
            <div class="input">
              <div class="label">关联度<span>*</span></div>
              <el-input v-model="correlationInput" placeholder="请输入关联度" clearable></el-input>
            </div>
            <div class="button">
              <div class="text" @click="clickSubmit">提交</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  nicheListById,
  listPageAutomation,
  correlationSet,
  businessJoinAutomation
} from '@/api/operatorCenter.js'
export default {
  components: {},
  props: {
    id: {
      type: String
    }
  },
  data() {
    //这里存放数据
    return {
      showTagBox: false, // 显示更多标签
      headData: {},
      businessLabelList: [], // 展示标签
      businessLabelNum: 0, // 标签数量
      // 查看人数-->1   分享人数-->2  收藏人数-->3  想联系人数-->4
      activeName: '0',
      // 关联度
      correlationList: [
        {
          value: '只选90%以上',
          id: 1
        },
        {
          value: '只选80%以上',
          id: 2
        },
        {
          value: '只选70%以上',
          id: 3
        }
      ],
      // 表格数据
      tableData: [],
      loading: false,
      // 分页总条数
      total: 0,
      // 视频按钮
      isControls: false,
      // 暂停按钮
      isSuspend: true,
      // 关联度
      radio: -1,
      // table表格按钮的选中数据存放处
      multipleSelection: [],
      // 表格发送数据
      query: {
        companyFullName: '', // 企业全称
        businessId: null, // 商机编号
        correlation: 0,
        id: 1,
        pageNum: 1,
        pageSize: 10,
        orders: 1 // 排序 默认传1 2->关联度升序;3->关联度降序
      }, // 表格数据查询传参
      // 表格企业简介
      enterpriseIntroShow: -1,
      // 加入已选
      poolShow: false,
      // 设置关联度
      correlationShow: false,
      // 设置关联度input
      correlationInput: '',
      // 设置关联度按钮的内容
      correlationText: '设置关联度'
    }
  },

  computed: {},
  filters: {
    timeFiltration(time) {
      if (time) {
        return time.split(' ')[0]
      }
    }
  },

  watch: {},
  created() {
    this.getHeadData()
    this.search()
  },
  //方法集合
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },
    //  加入已选的点击
    clickJoin() {
      this.poolShow = true
    },

    // 加入已选的弹出层 取消按钮
    clickClose() {
      this.poolShow = false
    },
    // 取消
    cancelPool() {
      this.poolShow = false
    },
    // 设置关联度的点击
    clickSet() {
      this.correlationShow = true
    },
    // 加入已选的确定按钮
    async clickPool() {
      if (this.multipleSelection.length == 0) {
        this.$message('请勾选数据后重试')
      } else {
        const paramList = []
        this.multipleSelection.forEach((item) => {
          const { companyId, correlation, id } = item
          const obj = {
            companyId,
            correlation,
            id
          }
          paramList.push(obj)
        })
        const query = {
          paramList,
          businessId: this.id
        }
        const { data: res } = await businessJoinAutomation(query)
        if (res.resultCode == 200) {
          this.poolShow = false
          this.search()
          this.$emit('tab', 1)
          return this.$message.success('加入成功')
        }
      }
    },
    // 头部数据
    async getHeadData() {
      const { data: res } = await nicheListById({ businessId: this.id })
      if (res.resultCode == 200) {
        this.headData = res.data
        const arr = this.headData.businessLabelList
        if (arr && arr.length) {
          this.businessLabelNum = arr.length
          arr.forEach((el, idx) => {
            if (idx < 2) {
              this.businessLabelList.push('#' + el)
            }
          })
        }
        // sessionStorage.setItem('time', res.data.updateTime)
      }
    },
    // 点击暂停按钮
    clickSuspend() {
      this.isControls = true
      this.isSuspend = !this.isSuspend
    },
    // 关联度的按钮点击
    clickRadio() {
      this.query.correlation = this.radio
      this.search()
    },
    // table表格按钮的选中数据
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async search() {
      this.query.businessId = this.id
      this.loading = true
      const { data: res } = await listPageAutomation(this.query)

      if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.tableData.forEach((item) => {
          if (item.companyLabel != null) {
            item.companyLabel = item.companyLabel.slice(0, 5)
          }
        })
        if (this.tableData.length != 0) {
          this.$refs.multipleTable.toggleAllSelection()
        }
        this.loading = false
      }
    },
    // 设置关联度的点击提交按钮
    async clickSubmit() {
      const ids = this.multipleSelection.map((el) => el.id)
      const query = {
        correlation: this.correlationInput,
        ids
      }
      const { data: res } = await correlationSet(query)
      if (res.resultCode === 200) {
        this.$message.success('设置成功!')
        this.correlationShow = false
        this.search()
      }
    },
    // 设置关联度点击关闭的回调
    correlationClose() {
      this.correlationInput = ''
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'correlation') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.query.orderByParam = 'm.correlation asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.query.orderByParam = 'm.correlation desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orderByParam = 'm.correlation desc'
          this.search()
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
.all_context {
  width: 100%;
  height: 100%;
  padding: 16px 0px 0 0px;
  box-sizing: border-box;
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .row {
    display: flex;
  }
  .head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 15px;
    box-sizing: border-box;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 100px;
        height: 70px;
        display: block;
        margin-right: 10px;
      }
      .info_box {
        display: flex;
        flex-flow: column;
        height: 100%;
        .border_box {
          width: 80px;
          height: 32px;
          text-align: center;

          border-radius: 2px;
          font-size: 12px;
          line-height: 32px;
          border: 0.5px solid #4e93fb;
          color: #4e93fb;
          background-color: #4e93fb1a;
        }
        .real_box {
          border: 0.5px solid #fb824e;
          color: #fb824e;
          background-color: #fb824e1a;
        }
        .name {
          font-size: 20px;
          font-weight: 550;
          line-height: 28px;
          margin-left: 10px;
        }
      }
      .bottom {
        padding-top: 20px;
        padding-right: 20px;
        margin-top: auto;
        position: relative;
        .tag {
          font-size: 14px;
          line-height: 20px;
          color: #4e93fb;
        }
        .tag + .tag {
          margin-left: 10px;
        }
        .pos_box {
          border-radius: 4px;
          position: absolute;
          top: 0;
          left: 100%;
          z-index: 99;
          box-shadow: 0px 2px 4px 0px #8f8a8a40;
          background-color: #fff;
          padding: 10px;
          box-sizing: border-box;
          min-width: 200px;
          display: flex;
          flex-flow: row wrap;
          // transition: display 1s ease 1s;
          li {
            width: 50%;
            margin-bottom: 10px;
          }
        }
      }
    }
    .right {
      display: flex;
      .figure {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4e93fb;
        width: 85px;
        height: 40px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 20px;
      }
      .person {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        width: 85px;
        height: 40px;
        color: #4e93fb;
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .context {
    display: flex;
    flex-flow: column;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    .span_tag {
      font-size: 14px;
      line-height: 20px;
      color: #4e93fb;
      margin-right: 10px;
      cursor: pointer;
    }
    .state {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        height: 40px;
        line-height: 40px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        .el-radio {
          margin-right: 10px;
        }
      }
      .right {
        display: flex;
        padding-right: 25px;
        .icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .tex {
          margin-left: 7px;
          color: #595959;
        }
        .time {
          color: #333333;
        }
      }
      /deep/.el-radio {
        margin-right: 0;
      }
    }
    .time_search {
      height: 80px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      justify-content: space-between;
      position: relative;
      .time {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        position: absolute;
        left: 0;

        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        // .item {
        //   color: #333333;
        //   font-size: 14px;
        //   cursor: pointer;
        //   padding: 15px 20px;
        // }
      }
      .search {
        display: flex;
        position: absolute;
        right: 0;
        .search_input {
          width: 430px;
        }
        .search_div {
          width: 74px;
          height: 40px;
          background-color: #448aff;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          transform: translateX(-2px);
        }
      }
    }

    .table {
      .imgbox {
        display: flex;
        justify-content: center;

        .img {
          width: 34px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .labelBox {
        .labelItem {
        }
      }
      .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .enterpriseIntro {
        // width: 100%;
        // height: 100%;
        width: 376px;
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
        // 控制行数
        -webkit-line-clamp: 2; //超出两行隐藏
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        color: #333333;
        position: relative;
      }
      .enterpriseIntroShow {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        // transform: translateX(50%);
        width: 376px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 999;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 20px 0px;
    }
  }
}

.add_select {
  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
  /deep/.el-dialog {
    margin-top: 40vh !important;
  }
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
      }
    }
    .context {
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;

        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
.color1 {
  color: #4e93fb;
}
</style>
